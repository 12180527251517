<template>
  <div class="students container pb-6">
    <div v-for="student in students" v-bind:key="student.id" class="block">
      <EzStudent
        :student="student"
        @click="onStudentClick(student)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { User } from '@/api-domain/user';
import { userService } from '@/services/user.service';
import EzStudent from '@/components/teams/EzStudent.vue';

function getTimestamp(student: User): string {
  if (!student.userAnswers || student.userAnswers.length === 0) {
    return '';
  }
  return student.userAnswers[0].timestamp;
}

@Options({
  components: {
    EzStudent,
  },
})
export default class Students extends Vue {
  students: User[] = [];

  fetchError = false;

  isFetched = false;

  async fetchStudents(): Promise<void> {
    try {
      const studentsInAlphabeticalOrder = await userService.getAll();
      this.students = studentsInAlphabeticalOrder.sort((a, b) => {
        if (getTimestamp(a) === getTimestamp(b)) {
          return 0;
        }
        return getTimestamp(a) > getTimestamp(b) ? -1 : 1;
      });
      this.isFetched = true;
    } catch {
      this.fetchError = true;
      // TODO: how to handle? polling in every 10 seconds?
      // try again button?
    }
  }

  async created(): Promise<void> {
    await this.fetchStudents();
  }

  onStudentClick(student: User): void {
    this.$router.push(`/students/${student.id}/answers`);
  }
}
</script>
<style scoped lang="scss">
.buttons {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>


import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { User } from '@/api-domain/user';

@Options({
  components: {
  },
})
export default class EzStudent extends Vue {
  @Prop({ required: true }) student!: User;

  getDateFromStudent(student: User) {
    if (!student.userAnswers || student.userAnswers.length === 0) {
      return '';
    }
    const date = new Date(student.userAnswers[0].timestamp);
    return date.toLocaleString();
  }
}

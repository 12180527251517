<template>
  <div class="card is-clickable">
    <div class="card-content">
      <div class="content">
        <h4>{{ student.name }}</h4>
        <p>{{ student.email }}</p>
        <p>Answers: <strong>{{ student._count?.userAnswers }}</strong></p>
        <p>Last answer: <strong>{{ getDateFromStudent(student) }}</strong></p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { User } from '@/api-domain/user';

@Options({
  components: {
  },
})
export default class EzStudent extends Vue {
  @Prop({ required: true }) student!: User;

  getDateFromStudent(student: User) {
    if (!student.userAnswers || student.userAnswers.length === 0) {
      return '';
    }
    const date = new Date(student.userAnswers[0].timestamp);
    return date.toLocaleString();
  }
}
</script>
